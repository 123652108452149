<template>
  <div class="SampleModify">
    <!--    <van-nav-bar-->
    <!--      title=""-->
    <!--      left-text="清空表单"-->
    <!--      right-text="按钮"-->
    <!--    />-->
    <van-sticky>
      <van-nav-bar>
        <template #left>
          <van-button @click="resetForm" plain type="danger" size="small">清空表单</van-button>
        </template>

      </van-nav-bar>
    </van-sticky>

    <van-form @submit="onSubmit" @failed="onFailed">
      <div class="info-desc">
        <van-icon name="todo-list" style="vertical-align: text-top;margin-right: 3px"/>
        <span>基本信息</span>
      </div>

      <van-field size="large" v-model="sample.saler_name" label="销售姓名"/>

      <van-field
        required
        size="large"
        v-model="sample.cooperation"
        label="合作伙伴"
        :rules="[{ required: true, message: '请输入客户/代理名称'}]"
      />


      <van-field
        required
        size="large"
        label="检测项目"
        :rules="[{ required: true, message: '请选择检测项目'}]"
      >
        <template #input>
          <van-radio-group v-model="sample.detect_type" direction="horizontal">
            <van-radio class="option" checked-color="#00a29a" name="mNGS_DNA">mNGS_DNA</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="mNGS_RNA">mNGS_RNA</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="mNGS_DNA+RNA">mNGS_DNA+RNA</van-radio>
          </van-radio-group>
        </template>
      </van-field>


      <van-field size="large" label="检测单位">
        <template #input>
          <van-radio-group v-model="sample.detect_institution" direction="horizontal">
            <van-radio class="option" checked-color="#00a29a" name="南京实验室">南京实验室</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="上海实验室">上海实验室</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="杭州实验室">杭州实验室</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="广州实验室">广州实验室</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="徐州实验室">徐州实验室</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="南通实验室">南通实验室</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="其他检测单位">其他检测单位</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field size="large" v-model="sample.other_detect_institution" label="其他检测单位"/>


      <van-field required size="large" label="是否收费" :rules="[{ required: true, message: '请选择是否收费'}]">
        <template #input>
          <van-radio-group v-model="sample.is_charge" direction="horizontal">
            <van-radio class="option-two" checked-color="#00a29a" name="是">是</van-radio>
            <van-radio class="option-two" checked-color="#00a29a" name="否">否</van-radio>
            <van-radio class="option-two" checked-color="#00a29a" name="未知">未知</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field
        size="large"
        v-model="sample.reipt_address"
        label="收货地址"
      />


      <div class="info-desc">
        <van-icon name="manager" style="vertical-align: text-top;margin-right: 3px"/>
        患者信息
      </div>


      <van-field
        required
        size="large"
        v-model="sample.patient_name"
        label="患者姓名"
        :rules="[{ required: true, message: '请输入患者姓名' }]"
      />

      <van-field required size="large" label="性别" :rules="[{ required: true, message: '请选择性别'}]">
        <template #input>
          <van-radio-group v-model="sample.gender" direction="horizontal">
            <van-radio class="option-two" checked-color="#00a29a" name="男">男</van-radio>
            <van-radio class="option-two" checked-color="#00a29a" name="女">女</van-radio>
            <van-radio class="option-two" checked-color="#00a29a" name="未知">未知</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field required size="large" v-model="sample.age" label="年龄"
                 :rules="[{ required: true, message: '请输入年龄' }]"/>

      <van-field size="large" v-model="sample.clinical_feature" label="临床表现"/>
      <van-field required size="large" v-model="sample.diagnosis" label="临床诊断"
                 :rules="[{ required: true, message: '请输入临床诊断' }]"/>
      <van-field size="large" v-model="sample.medication" label="用药情况"/>

      <div class="info-desc">
        <van-icon name="cluster" style="vertical-align: text-top;margin-right: 3px"/>
        医院信息
      </div>

      <van-field size="large" v-model="sample.hospital" label="医院" placeholder="请输入医院"/>

      <van-field required size="large" label="科室" :rules="[{ required: true, message: '请选择科室'}]">
        <template #input>
          <van-radio-group v-model="sample.department" direction="horizontal">
            <van-radio class="option" checked-color="#00a29a" name="感染科">感染科</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="呼吸科">呼吸科</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="急诊科">急诊科</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="血液科">血液科</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="肿瘤科">肿瘤科</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="神经内科">神经内科</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="风湿免疫科">风湿免疫科</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="ICU">ICU</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="其他科室">其他科室</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field v-if="sample.department === '其他科室' " required size="large"
                 :rules="[{ required: true, message: '请输入其他科室' }]" v-model="sample.other_department" label="其他科室"/>
      <van-field v-else size="large" v-model="sample.other_department" label="其他科室"/>

      <van-field
        required
        size="large"
        v-model="sample.doctor_name"
        label="医生姓名"
        :rules="[{ required: true, message: '请输入医生姓名' }]"
      />

      <van-field size="large" v-model="sample.doctor_mobile" label="医生电话"/>


      <div class="info-desc">
        <van-icon name="bag" style="vertical-align: text-top;margin-right: 3px"/>
        样本信息
      </div>

      <van-field size="large" right-icon="scan" v-model="sample.knoin_no" label="样本编号"/>

      <van-field size="large" label="对比样本">
        <template #input>
          <van-radio-group v-model="sample.is_compare" direction="horizontal">
            <van-radio class="option-two" checked-color="#00a29a" name="是">是</van-radio>
            <van-radio class="option-two" checked-color="#00a29a" name="否">否</van-radio>
            <van-radio class="option-two" checked-color="#00a29a" name="未知">未知</van-radio>
          </van-radio-group>
        </template>
      </van-field>


      <van-field required size="large" label="样本类型" :rules="[{ required: true, message: '请选择样本类型' }]">
        <template #input>
          <van-radio-group v-model="sample.sample_type" direction="horizontal">
            <van-radio class="option" checked-color="#00a29a" name="脑脊液">脑脊液</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="组织">组织</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="外周血">外周血</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="痰液">痰液</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="胸腹水">胸腹水</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="血浆">血浆</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="肺泡灌洗液">肺泡灌洗液</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="其他样本类型">其他样本类型</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field v-if="sample.sample_type === '其他样本类型' " required size="large" v-model="sample.other_sample_type"
                 :rules="[{ required: true, message: '请输入样本类型' }]" label="其他样本类型"/>

      <van-field v-else size="large" v-model="sample.other_sample_type" label="其他样本类型"/>

      <van-field required size="large" v-model="sample.sample_size" label="样本量"
                 :rules="[{ required: true, message: '请输入样本量' }]"/>

      <van-field required size="large" label="快递方式" :rules="[{ required: true, message: '请选择快递方式' }]">
        <template #input>
          <van-radio-group v-model="sample.convey_way" direction="horizontal">
            <van-radio class="option" checked-color="#00a29a" name="顺丰">顺丰</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="高铁">高铁</van-radio>
            <van-radio class="option" checked-color="#00a29a" name="其他">其他</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field v-if="sample.convey_way === '顺丰'" right-icon="scan" size="large" v-model="sample.express_no"
                 label="快递单号"/>

      <van-field v-if="sample.convey_way === '高铁'" right-icon="scan" size="large" v-model="sample.railway_no"
                 label="车次号"/>


      <van-field
        required
        readonly
        clickable
        size="large"
        :value="sample.sampling_date"
        label="采样日期"
        :rules="[{ required: true, message: '请选择采样日期' }]"
        @click="showSamplingDate = true"
      />
      <van-calendar v-model="showSamplingDate" @confirm="confirmSamplingDate" :min-date="minDate" color="#00a29a"/>
      <!--    <van-calendar show="showCalendar" color="#00a29a" bind:confirm="confirmSamplingDate" :min-date="minDate"/>-->
      <van-field
        required
        readonly
        clickable
        size="large"
        v-model="sample.convey_date"
        label="送检日期"
        :rules="[{ required: true, message: '请选择送检日期' }]"
        @click="showConveyDate = true"
      />
      <van-calendar v-model="showConveyDate" @confirm="confirmConveyDate" :min-date="minDate" color="#00a29a"/>


      <van-field size="large" label="是否做结核qPCR">
        <template #input>
          <van-radio-group v-model="sample.has_qpcr" direction="horizontal">
            <van-radio class="option-two" checked-color="#00a29a" name="是">是</van-radio>
            <van-radio class="option-two" checked-color="#00a29a" name="否">否</van-radio>
            <van-radio class="option-two" checked-color="#00a29a" name="未知">未知</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field size="large" label="关注病原">
        <template #input>
          <van-checkbox-group v-model="sample.pathogen_list" direction="horizontal">
            <van-checkbox class="option" name="病毒" checked-color="#00a29a" shape="square">病毒</van-checkbox>
            <van-checkbox class="option" name="真菌" checked-color="#00a29a" shape="square">真菌</van-checkbox>
            <van-checkbox class="option" name="细菌" checked-color="#00a29a" shape="square">细菌</van-checkbox>
            <van-checkbox class="option" name="寄生虫" checked-color="#00a29a" shape="square">寄生虫</van-checkbox>
            <van-checkbox class="option" name="分枝杆菌" checked-color="#00a29a" shape="square">分枝杆菌</van-checkbox>
            <van-checkbox class="option" name="支原体/衣原体" checked-color="#00a29a" shape="square">支原体/衣原体</van-checkbox>
            <van-checkbox class="option" name="其他" checked-color="#00a29a" shape="square">其他</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <van-field size="large" v-model="sample.detect_result" label="培养结果"/>
      <van-field size="large" v-model="sample.lymphocytes" label="淋巴细胞" placeholder="单位：%"/>
      <van-field size="large" v-model="sample.neutr_granule" label="中性粒细胞" placeholder="单位：%"/>
      <van-field size="large" v-model="sample.CRP" label="CRP" placeholder="单位：mg/L"/>
      <van-field size="large" v-model="sample.PCT" label="PCT" placeholder="单位：ng/ml"/>
      <van-field size="large" v-model="sample.bio_info" label="生物信息补充"/>
      <van-field size="large" v-model="sample.remarks" label="备注"/>
      <van-field required size="large" label="附件上传" :rules="[{ required: true, message: '请上传附件' }]">
        <template #input>
          <van-uploader :before-delete="beforeDelete" v-model="imgList" :after-read="afterRead" :max-count="1"/>
        </template>
      </van-field>

      <div style="margin: 16px;color: #00a29a">
        <!--      <van-button :to="{name:'SampleList', query: {id:1}}" class="next" round block type="primary" native-type="submit">-->
        <!--        提交-->
        <!--      </van-button>-->
        <!--      <van-button class="next" round block type="primary" native-type="submit">-->
        <!--        提交-->
        <!--      </van-button>-->
        <van-button round block type="primary" native-type="submit">提交</van-button>
      </div>
    </van-form>


    <!--    <van-button type="primary" @click=scanCode>扫码</van-button>-->
    <!--    <van-button type="primary" @click=checkApi>查看接口</van-button>-->
  </div>

</template>

<script>
import { Dialog, Toast } from 'vant'
import {
  attachCreateApi,
  attachDeleteApi,
  createSampleApi,
  getSampleApi,
  modifySampleApi,
  submitSampleApi, updateSampleApi
} from '@/api/sample'
import { FRONTEND_URL } from '@/const'

export default {
  metaInfo: {
    title: '修改送检信息',
  },
  name: 'SampleModify',
  data() {
    return {
      attachId: '',
      sample: {
        'openid': '',
        'record_id': '',
        'saler_name': '',
        'cooperation': '',
        'detect_type': '',
        'detect_institution': '',
        'other_detect_institution': '',
        'is_charge': '',
        'reipt_address': '',
        'hospital': '',
        'department': '',
        'other_department': '',
        'doctor_name': '',
        'doctor_mobile': '',
        'patient_name': '',
        'gender': '',
        'age': '',
        'clinical_feature': '',
        'diagnosis': '',
        'medication': '',
        'knoin_no': '',
        'is_compare': '',
        'sample_type': '',
        'other_sample_type': '',
        'sample_size': '',
        'convey_way': '顺丰',
        'express_no': '',
        'railway_no': '',
        'sampling_date': '',
        'convey_date': '',
        'has_qpcr': '',
        'pathogen': '',
        'pathogen_list': [],
        'detect_result': '',
        'lymphocytes': '',
        'neutr_granule': '',
        'CRP': '',
        'PCT': '',
        'bio_info': '',
        'remarks': '',
        'img_url': '',
        'attachs': []
      },
      imgList: [],
      sampleRecord: [],
      showPicker: false,
      uploader: [],
      showSamplingDate: false,
      showConveyDate: false,
      minDate: new Date(2021, 0, 1), // 最小日期是2021.1.1
    }
  },
  created() {
    this.initUser()
    this.getSample(this.$route.query.id)
  },
  methods: {
    getSample(id) {
      getSampleApi(id).then(res => {
        this.sample = res.data
        this.imgList.push({ url: res.data.img_url, isImage: true })

      })
    },
    beforeDelete() {
      this.imgList = []
      attachDeleteApi(this.attachId).then(res => {
        console.log(res.data)
      })
    },
    resetForm() {
      this.sample = {
        'openid': '',
        'saler_name': '',
        'cooperation': '',
        'detect_type': '',
        'detect_institution': '',
        'other_detect_institution': '',
        'is_charge': '',
        'reipt_address': '',
        'hospital': '',
        'department': '',
        'other_department': '',
        'doctor_name': '',
        'doctor_mobile': '',
        'patient_name': '',
        'gender': '',
        'age': '',
        'clinical_feature': '',
        'diagnosis': '',
        'medication': '',
        'knoin_no': '',
        'is_compare': '',
        'sample_type': '',
        'other_sample_type': '',
        'sample_size': '',
        'convey_way': '',
        'express_no': '',
        'railway_no': '',
        'sampling_date': '',
        'convey_date': '',
        'has_qpcr': '',
        'pathogen': '',
        'pathogen_list': [],
        'detect_result': '',
        'lymphocytes': '',
        'neutr_granule': '',
        'CRP': '',
        'PCT': '',
        'bio_info': '',
        'remarks': '',
        'img_url': '',
        'attachs': []
      }
      this.imgList = []
    },
    afterRead(upload) {
      upload.status = 'uploading'
      upload.message = '上传中...'
      // 此时可以自行将文件上传至服务器
      attachCreateApi(upload.file).then(res => {
        this.sample.img_url = FRONTEND_URL + res.data.img.split('8000')[1]
        this.attachId = res.data.id
        upload.status = ''
        upload.message = ''
      }).catch(() => {
        upload.status = 'failed'
        upload.message = '上传失败'
      })
      // console.log(file)
    },
    onFailed(errorInfo) {
      let errMsg = ''
      errorInfo.errors.forEach(item => {
        errMsg += item.message + '\n'
      })
      Dialog.alert({
        message: errMsg,
      })
    },
    confirmSamplingDate(date) {
      this.sample.sampling_date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      this.showSamplingDate = false
    },
    confirmConveyDate(date) {
      this.sample.convey_date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      this.showConveyDate = false
    },
    onSubmit() {
      Dialog.confirm({
        message: '确定提交样本吗？',
        confirmButtonColor: '#00a29a'
      }).then(() => {
        this.modifySample()
      })
    },

    modifySample() {
      const sample = this.sample
      sample.openid = this.userInfo.openid
      if (sample.pathogen_list !== undefined){
        sample.pathogen = sample.pathogen_list.join()
      }
      const detect_institution = sample.detect_institution
      const sample_type = sample.sample_type
      if (detect_institution === '其他检测单位' || !detect_institution) {
        sample.detect_institution = sample.other_detect_institution
      }
      if (sample_type === '其他样本类型' || !sample_type) {
        sample.sample_type = sample.other_sample_type
      }
      Object.keys(sample).forEach((key) => (sample[key].length === 0) && delete sample[key])
      modifySampleApi(this.$route.query.id, sample).then(res => {

        const submitData = {
          CRP: sample.CRP,
          PCT: sample.PCT,
          WBC: sample.WBC,
          age: sample.age,
          bioInfo: sample.bio_info,
          clientName: sample.cooperation,
          salerName: sample.saler_name,
          clientNo: sample.knoin_no,
          clinicalManifestations: sample.clinical_feature,
          conveyDate: sample.convey_date,
          department: sample.department,
          detectResult: sample.detect_result,
          detectType: sample.detect_type,
          diagnosis: sample.diagnosis,
          doctor: sample.doctor_name,
          gender: sample.gender,
          hasQpcr: sample.has_qpcr,
          hospital: sample.hospital,
          knoinNo: sample.knoin_no,
          lymphocytes: sample.lymphocytes,
          medication: sample.medication,
          neutrGranule: sample.neutr_granule,
          pathogen: sample.pathogen,
          patientName: sample.patient_name,
          sampleSize: sample.sample_size,
          sampleType: sample.sample_type,
          samplingDate: sample.sampling_date,
          imgUrl: sample.img_url,
          detectInstitution: sample.detect_institution,
          isCharge: sample.is_charge,
          reiptAddress: sample.reipt_address,
          isCompare: sample.is_compare,
          conveyWay:sample.convey_way,
          expressNo:sample.express_no,
        }
        Object.keys(submitData).forEach(key => (submitData[key] === undefined) && delete submitData[key])
        updateSampleApi(this.$route.query.record_id, submitData).then(() => {
          Toast.success('提交成功')
          this.$router.push({ name: 'SampleList' })
        })
      }).catch(err => {
        Toast.fail(err.response)
      })
    },
    load(value, index) {
      const record = JSON.parse(localStorage.getItem('sampleRecord'))[index]
      this.sample = record
      this.imgList = []
      this.imgList.push({ url: record.img_url })
      this.showPicker = false
    },
    save() {
      const patientName = this.sample.patient_name
      if (!patientName) {
        Dialog.alert({
          message: '输入患者姓名后才能保存',
        })
      } else {
        let sampleRecord = JSON.parse(localStorage.getItem('sampleRecord'))
        if (!sampleRecord) {
          sampleRecord = []
        }
        sampleRecord.push(this.sample)
        localStorage.setItem('sampleRecord', JSON.stringify(sampleRecord))
        Toast.success('保存成功')
      }

    },
    showPopup() {
      const sampleRecord = JSON.parse(localStorage.getItem('sampleRecord'))
      if (sampleRecord !== null) {
        this.sampleRecord = []
        sampleRecord.forEach((item) => {
          const recordTitle = `患者：${item.patient_name} 医院：${item.hospital}`
          this.sampleRecord.push(recordTitle)
        })
        this.showPicker = true
      } else {
        this.showPicker = true
      }

    },
    scanCode() {
      this.$wx.scanQRCode({
        needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ['barCode'], // 可以指定扫二维码还是一维码，默认二者都有
        success: function(res) {
          var result = res // 当needResult 为 1 时，扫码返回的结果
          //console.log('resultStr', result)
        }
      })
    },
    checkApi() {
      this.$wx.checkJsApi({
        jsApiList: ['chooseImage'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
        success: function(res) {
          //console.log(res)
          // 以键值对的形式返回，可用的api值true，不可用为false
          // 如：{"c heckResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
        }
      })
    }
  }
}
</script>

<style scoped>
#btn-save {
  margin-right: 10px;
}

.option {
  font-size: 18px;
  margin-bottom: 14px
}

.option-two {
  font-size: 18px;
}

.info-desc {
  padding: 10px;
  color: #00a29a;
}

.van-cell >>> .van-icon-scan {
  color: #00a29a;
  font-size: 25px;
  font-weight: bold;
}

</style>
